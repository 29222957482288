.btn {
    padding: 0.35em 3em;
    border: none;
    background: $secondary-color;
    border-radius: 0;
    outline: none !important;
    &:active,
    &:hover,
    &:focus {
        outline: none !important;
    }
    &--menu {
        position: absolute;
        left: 15%;
        top: 1rem;
        padding: 1rem 1rem 1rem 3rem;
        font-size: 0;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        cursor: pointer;
        color: $bg-primary-color;
        border: none;
        background: none;
        outline: none !important;
        &:hover{
            color: $bg-primary-color;
        }
        span {
            width: 2rem;
            height: 0.3rem;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -0.15rem;
            margin-right: 0.25rem;
            display: inline-block;
            vertical-align: middle;
            background: $bg-primary-color;
            &:before,
            &:after {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                display: block;
                content: '';
                background: $bg-primary-color;
            }
            &:before {
                top: -200%;
            }
            &:after {
                bottom: -200%;
            }
        }
        &.active {
            color: #fff;
            span {
                background: #fff;
                &:after,
                &:before {
                    background: #fff;
                }
            }
        }
        @media screen and (min-width: $desktop-min-breakpoint) {
            font-size: 1.5rem;
        }
    }
}
.card {
    border-radius: 0;
    border: none;
    font-weight: 600;
    &--thumb {
        position: relative;
        background: no-repeat center center;
        background-size: cover;
        z-index: 1;
        &:before {
            width: 100%;
            height: 0;
            padding-bottom: 75%;
            display: block;
            content: '';
        }
    }
    &--description {
        padding: 7%;
        color: #fff;
        background: $color1;
        .deco--rightBottomDonut {
            width: 40%;
            right: 0;
            bottom: 0;
            mask: url('/assets/images/deco-rightBottomDonut.svg') no-repeat right bottom;
            mask-size: contain;
            background: $color1;
        }
        > div {
            position: relative;
            z-index: 1;
        }
        h3 {
            font-size: 3em;
            font-weight: 600;
            margin-bottom: 0.5em;
        }
    }
    &--preescolar {
        .deco--rightBottomDonut {
            background: $color4;
        }
    }
    &--primaria {
        .card {
            &--description {
                background: $color2;
            }
        }
    }
    &--secundaria {
        .card {
            &--description {
                background: $color3;
            }
        }
    }
    &--preparatoria {
        .card {
            &--description {
                background: $color4;
            }
        }
    }
}
.deco {
    position: absolute;
    background: no-repeat center center;
    background-size: contain;
    &:before {
        width: 100%;
        height: 0;
        position: relative;
        display: block;
        content: '';
    }
    &--aniversary {
        left: 0;
        bottom: 0;
        background-image: url('/assets/images/deco-aniversary.svg');
        &:before {
            padding-bottom: 150%;
        }
    }
    &--circle {
        left: 0;
        bottom: 0;
        background-image: url('/assets/images/deco-circle.svg'); 
        background-position: left bottom;
        &:before {
            padding-bottom: 26%;
        }
    }
    &--leftTopBubble {
        left: 0;
        top: 0;
        background-image: url('/assets/images/deco-leftTopBubble.svg');
        background-position: 50% 0;
        background-size: 100%;
        &:before {
            padding-bottom: 213%;
        }
    }
    &--leftTopTriangle {
        left: 0;
        top: 0;
        background-image: url('/assets/images/deco-leftTopTriangle.svg');
        background-position: left top;
        &:before {
            padding-bottom: 220%;
        }
    }
    &--rightBottomBubble {
        right: 0;
        bottom: 0;
        background-image: url('/assets/images/deco-rightBottomBubble.svg');
        background-position: right bottom;
        &:before {
            padding-bottom: 170%;
        }
    }
    &--rightBottomDonut {
        right: 0;
        bottom: 0;
        background-image: url('/assets/images/deco-rightBottomDonut.svg');
        background-position: right bottom;
        &:before {
            padding-bottom: 120%;
        }
    }
    &--mask {
        background: none;
        &:before {
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: contain;
            -webkit-mask-position: center center;
            mask-repeat: no-repeat;
            mask-position: center center;
            mask-size: contain; 
        }
        &.deco {
            &--rightBottomDonut {
                &:before {
                    -webkit-mask-image: url('/assets/images/deco-rightBottomDonut.svg');
                    mask-image: url('/assets/images/deco-rightBottomDonut.svg');
                    mask-position: right bottom;
                }
            }
            &--circle {
                &:before {
                    padding-bottom: 26%;
                    -webkit-mask-image: url('/assets/images/deco-circle.svg'); 
                    mask-image: url('/assets/images/deco-circle.svg'); 
                    mask-position: left bottom;
                }
            }
        }       
    }
}
.downloads-list {
    list-style: none;
    li {
        min-height: 3em;
        position: relative;
        padding-left: 4em;
        margin: 1.5em 0;
        a {
            color: #fff;
            text-decoration: none;
            .icon {
                width: 3em;
                height: 3em;
                position: absolute;
                left: 0;
                top: 0;
                border: 1px solid #fff;
                background: none;
            }
        }
    }
}
.full-content {
    padding: 10vh 5%;
    &--intro {
        padding-right: 10%;
    }
}
.half-card {
    &--thumb,
    &--description {
        width: 100%;
        min-height: 70vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10%;
        padding-right: 10%;
    }
    &--thumb {
        position: relative;
        background: no-repeat center center $color3;
        background-size: cover;
        h3 {
            width: 100%;
            font-size: 3.5em;
            font-weight: 600;
            color: #fff;
            display: block;
        }
        .deco {
            width: 50%;
            &:before {
                background: $color3;
            }
            &.deco--circle {
                width: 30%;
            }
        }
    }
    &--description {
        .section-subtitle {
            margin-bottom: 1.5em;
        }
        .btn--primary {
            margin-top: 1.5em;
        }
        &--extended {
            padding: 0 5% 0;
        }
    }
    @media screen and (min-width: $desktop-min-breakpoint) {
        display: flex;
        &--thumb,
        &--description {
            width: 50%;
            min-height: 100vh;
        }
    }
}
.icon {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    -webkit-mask: no-repeat center center;
    -webkit-mask-size: contain;
    mask: no-repeat center center;
    mask-size: contain;
    background: $primary-color;
    &--white {
        background: #fff;
    }
    &--location {
        -webkit-mask-image: url('/assets/images/icon-marker.svg');
        mask-image: url('/assets/images/icon-marker.svg');
    }
    &--phone {
        -webkit-mask-image: url('/assets/images/icon-phone.svg');
        mask-image: url('/assets/images/icon-phone.svg');
    }
    &--microsite {
        -webkit-mask-image: url('/assets/images/icon-microsite.svg');
        mask-image: url('/assets/images/icon-microsite.svg');
    }
    &--360 {
        -webkit-mask-image: url('/assets/images/icon-360.svg');
        mask-image: url('/assets/images/icon-360.svg');
    }
    &--facebook {
        -webkit-mask-image: url('/assets/images/icon-facebook.svg');
        mask-image: url('/assets/images/icon-facebook.svg');
    }
}
.icon-card {
    text-align: center;
    .icon {
        width: 4em;
        height: 4em;
    }
    &--desc {
        color: $color4;
        font-weight: 600;
        font-size: 0.75em;
    }
}
.icons-mosaic {
    .row {
        padding-top: 1em;
        padding-bottom: 1em;
    }
    .icon {
        background: none;
        border: 1px solid #ddd;
    }
}
.interactive-map {
    background: $color1;
    &--nav {
        padding: 5vh 0;
        text-align: center;
        li {
            display: inline-block;
            margin: 0 1em;
            font-size: 1.5em;
            font-weight: 600;
            border-bottom: 3px solid transparent;
            &:nth-child(1) {
                color: $color1;
            }
            &:nth-child(2) {
                color: $color2;
            }
            &:nth-child(3) {
                color: $color3;
            }
            &:nth-child(4) {
                color: $color4;
            }

            &.selected {
                color: #fff;
                border-bottom-color: #fff;
            }
        }
    }
    &--explore {
        min-height: 50vh;
        background: gray;
    }
    &--directory {
        text-align: center;
        ul {
            margin: 0;
            padding: 0;
            li {
                position: relative;
                display: inline-block;
                padding: 2em;
                font-size: 1em;
                font-weight: 600;
                .icon {
                    width: 1.5em;
                    height: 1.5em;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 2em;
                }
            }
        }
    }
}
.intro-areas {
    position: relative;
    .deco--leftTopBubble {
        width: 20%;
        left: 0;
        top: -10%;
        z-index: -1;
    }
    &--identity,
    &--identity-description {
        padding: 15vh 10%;
    }

    @media screen and (min-width: $desktop-min-breakpoint) {
        &--identity,
        &--identity-description {
            padding-top: 15vh;
            padding-bottom: 15vh;
        }
        &--identity {
            padding-left: 20%;
            padding-right: 10%;
        }
        &--identity-description {
            padding-left: 5%;
            padding-right: 15%;
        }

    }
}
.lg-aniversary {
    width: 100%;
    display: inline-block;
    background: url('/assets/images/lg-valladolid80.svg') no-repeat center center;
    background-size: contain;
    &:before {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        position: relative;
        display: block;
        content: '';
    }
}
.lg-champagnat {
    width: 100%;
    display: inline-block;
    &:before {
        width: 100%;
        height: 0;
        padding-bottom: 30%;
        position: relative;
        display: block;
        content: '';
        background: url('/assets/images/lg-champagnat.svg') no-repeat center center;
        background-size: contain;
    }
}
.lg-company {
    width: 100%;
    display: inline-block;
    background: url('/assets/images/lg-valladolid.svg') no-repeat center center;
    background-size: contain;
    &:before {
        width: 100%;
        height: 0;
        padding-bottom: 30%;
        position: relative;
        display: block;
        content: '';
    }
    &--white {
        -webkit-mask: url('/assets/images/lg-valladolid.svg') no-repeat center center;
        -webkit-mask-size: contain;
        mask: url('/assets/images/lg-valladolid.svg') no-repeat center center;
        mask-size: contain;
        background: #fff;
    }
}
.lg-valla {
    width: 100%;
    display: inline-block;
    background: url('/assets/images/lg-valla.png') no-repeat center center;
    background-size: contain;
    &:before {
        width: 100%;
        height: 0;
        padding-bottom: 70%;
        position: relative;
        display: block;
        content: '';
    }
}
.lg-vallaID {
    width: 100%;
    display: inline-block;
    background: url('/assets/images/lg-vallaid.svg') no-repeat center center;
    background-size: contain;
    &:before {
        width: 100%;
        height: 0;
        padding-bottom: 50%;
        position: relative;
        display: block;
        content: '';
    }
}
.lg-maristas {
    width: 100%;
    display: block;
    background: transparent;
    &:before {
        width: 100%;
        height: 0;
        padding-bottom: 25%;
        position: relative;
        display: block;
        content: '';
        -webkit-mask-image: url('/assets/images/lg-maristas.svg');
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center center;
        -webkit-mask-size: contain;
        mask-image: url('/assets/images/lg-maristas.svg');
        mask-repeat: no-repeat;
        mask-position: center center;
        mask-size: contain;
        background: $primary-color;
    }
    &--white {
        &:before {
            background: #fff;
        }
    }
}
.news-card {
    border: 1px solid #ccc;
    border-radius: 30px;
    padding: 20px 10px;
    &--header {
        position: relative;
        margin-bottom: 1em;
    }

    .post {
        color: $default-color;
        &--photo {
            width: 2.5em;
            height: 2.5em;
            float: left;
            margin-right: 1em;
            border-radius: 5px;
        }
        &--profile-name {
            font-weight: 600;
        }
        &--icon {
            position: absolute;
            right: 0;
            top: 0;
            .icon {
                background: #3b5998;
            }
        }
        &--thumb {
            border: 1px solid #ccc;
            &:before {
                width: 100%;
                height: 0;
                padding-bottom: 50%;
                display: block;
                content: '';
            }
        }
        &--content {
            margin-top: 1em;
            font-size: 0.85em;
        }
    }
}
.news-feed {
    margin: 15vh 0;
    ul {
        margin: 15vh 0 0;
        list-style: none;
        li {
            margin: 0;
            padding: 0;
            
        }
    }
    @media screen and (min-width: $desktop-min-breakpoint) {
        ul {
            display: flex;
            align-items: center;
            li {
                padding: 0 1%;
            }
        }
    }
}
.partnerships-list {
    margin: 10vh 0;
    li {
        padding: 10vh 4em;
        align-items: center;
    }
}
.philosophy {
    position: relative;
    padding: 25vh 5%;
    margin-bottom: 5vh;
    background: $secondary-color;
    overflow: hidden;
    .deco--leftTopBubble {
        width: 20%;
        position: absolute;
        left: 0;
        top: -55%;
        -webkit-mask: url('/assets/images/deco-leftTopTriangle2.svg') no-repeat left bottom;
        -webkit-mask-size: 100%;
        mask: url('/assets/images/deco-leftTopTriangle2.svg') no-repeat left bottom;
        mask-size: 100%;
        background: $color4;
    }
    .deco--rightBottomDonut {
        width: 15%;
        position: absolute;
        right: 0;
        bottom: 0;
        &:before {
            padding-bottom: 100%;
        }
    }
    .col-md-4 {
        padding: 0 5%;
    }
    h3 {
        font-size: 3.5em;
        font-weight: 800;
        margin-bottom: 0.5em;
    }
}
.quote {
    font-size: 2em;
    b {
        font-size: 1.5em;
        line-height: 100%;
        font-weight: 700;
    }
}
.section-subtitle {
    color: $secondary-color;
    b {
        color: $primary-color;
    }
    &--primary {
        color: $primary-color;        
    }
    &--inverted {
        color: $primary-color;
        b {
            color: $secondary-color;
            font-size: 2em;
            line-height: 100%;
        }
    }
    &--emphasis {
        font-size: 3em;
        color: $color4;
        margin-bottom: 1em;
    }
}
.tabs-menu {
    padding: 10%;
    ul {
        margin: 10vh 0;
        padding: 0;
        border: none !important;
        li {
            width: 100%;
            position: relative;
            margin: 0;
            display: block;
            font-size: 4em;
            font-weight: 600;
            list-style: none;
            cursor: pointer;
            border: none !important;
            &:before {
                opacity: 0;
                width: 0.25em;
                height: 0.75em;
                display: block;
                position: absolute;
                left: -0.5em;
                top: 0.375em;
                content: '';
                transition: .3s all ease-in-out;
            }
            &:nth-child(1) {
                color: $color1 !important;
                &:before {
                    background: $color1;
                }
            }
            &:nth-child(2) {
                color: $color2 !important;
                &:before {
                    background: $color2;
                }
            }
            &:nth-child(3) {
                color: $color3 !important;
                &:before {
                    background: $color3;
                }
            }
            &:nth-child(4) {
                color: $color4 !important;
                &:before {
                    background: $color4;
                }
            }
            &.active {
                &:before {
                    opacity: 1;
                }
            }
        }
    }
    &--extended {
        padding-left: 0;
        padding-right: 0;
        ul {
            li {
                font-size: 3vw;
            }
        }
    }
}
.text-primary {
    color: $primary-color;
}
.text-secondary {
    color: $secondary-color !important;
}
.text-color1 {
    color: $color1;
}
.text-color2 {
    color: $color2;
}
.text-color3 {
    color: $color3;
}
.text-color4 {
    color: $color4;
}

.video { 
    width: 100%;
    position: relative;
    &:before {
        width: 100%;
        height: 0;
        padding-bottom: 65%;
        display: block;
        content: '';
    }
    &--element {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
}



// Cover
.page-cover {
    width: 100%;
    min-height: 100vh;
    position: relative;
    &--background {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: no-repeat center right;
        background-size: cover;
    }
    &--caption {
        position: relative;
        text-align: center;
        padding: 20vh 30vw 30vh 20vw;
        z-index: 1;
        &--spaced {
            padding-top: 40vh;
        }
        img,
        svg {
            max-width: 100%;
        }
        .lg-aniversary {
            width: 30%;
        }
    }
    &--frame {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        .deco {
            &--leftTopTriangle {
                width: 20%;
            }
            &--rightBottomBubble {
                width: 25%;
                right: 0;
                bottom: 0;
            }
            &--aniversary {
                width: 20%;
                height: calc(100% - 5em);
                left: 0;
                bottom: 0;
                background-size: 150%;
                background-position: right top;
            }
            &--circle {
                width: 30%;
                left: 0;
                bottom: 0;
                text-align: center;
                span {
                    width: 40%;
                    display: inline-block;
                    margin-bottom: 5vh;
                    margin-right: 10%;
                }
            }
        }
    }
    &--nav {
        width: 100%;
        position: absolute;
        bottom: 1em;
        z-index: 3;
        text-align: center;
        li {
            position: relative;
            display: inline-block;
            margin: 0 1em;
            color: #fff;
            font-weight: 600;
            a {
                position: relative;
                color: inherit;
                text-decoration: none;
            }
            &:before {
                width: 0.25em;
                height: 0.25em;
                display: block;
                content: '';
                position: absolute;
                left: -1em;
                top: calc(50% - 0.125em);
                border-radius: 50%;
                background: #fff;
            }
            &:first-child {
                &:before {
                    display: none;
                }
            }
        }
    }
}