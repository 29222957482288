/*
* Main app file
* Run with: npm run watch (root directory)
*/


/**
*  Global variables
**/

$mobile-breakpoint: 1023px;
$desktop-min-breakpoint: 1024px;
$flex-reset: 0 1 auto;

$default-color: #476981;
$primary-color: #476981;
$secondary-color: #f9a836;
$bg-primary-color: #083a63;

$color1 : #ffc82b;
$color2 : #00abeb;
$color3 : #32a357;
$color4 : #cb2128;

/**
*  Required files
**/

@import './fonts';
@import './layout';
@import './components';
//@import './animate.css';