/**
*
*   Fonts file
*/

/**
* Imports
**/

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,500;0,700;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Armata&display=swap');

/**
* Variables
*/

$font-general : "Raleway", sans-serif;
$font-styled : "Armata", serif;

.font-general, %font-regular {
    font-family: "Raleway", sans-serif; 
}
.font-styled, %font-styled {
    font-family: "Armata", serif;
}
.font-titles, %font-titles {
    font-family: "Raleway", serif;
}
.font-titles-label, %font-titles-label {
    font-family: "Raleway", sans-serif; 
}