/**
*
*   Layout File
*/

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    //border-bottom: 1px solid red;
}
img {
    max-width: 100%;
}

body {
    overflow-x: hidden;
    font-size: 1.1rem;
    font-weight: 400;
    color: $primary-color;
    font-family: $font-general;
}

.main-footer {
    color: #fff;
    background: $bg-primary-color;
    a {
        color: #fff;
        text-decoration: none;
    }
    &--credits {
        padding: 3vh 5% 3vh;
        .col-md-4 {
            &:nth-child(1) {
                padding-left: 40%;
                padding-right: 40%;
            }
            &:nth-child(2) {
                font-size: 0.55em;
                font-weight: 600;
                span {
                    width: 25%;
                    display: inline-block;
                    &:nth-child(2){
                        width: 15%;
                    }
                }
            }
            &:nth-child(3) {
                padding: 0 12%;
                text-align: center;
            }
            @media screen and (min-width: $desktop-min-breakpoint) {
                padding-left: 0;
                padding-right: 0;
                &:nth-child(1) {
                    padding-left: 10%;
                    padding-right: 15%;
                }   
            }
        }
    }
    &--legals {
        text-align: center;
        margin-top: 4em;
    }
}
.main-header {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    &--menu {
        position: relative;
        z-index: 2;
    }
    &--brand {
        padding: 15px 5% 0;
    }
    &--quick-links {
        position: relative;
        text-align: center;
        z-index: 2;
        img {
            max-width: 60%;
        }
    }
    .main-navbar {
        display: flex;
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background: $color2;
        transform: translateX(-100%);
        transition: 0.5s all ease-in-out;
        //z-index: 1;
        overflow: auto;
        &--links {
            flex: 1;
            min-height: 100%;
            padding-top: 25vh;
            padding-left: 5%;
            font-size: 3em;
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    ul {
                        font-size: 0.5em;
                    }
                }
            }
            a {
                color: #fff;
                font-weight: 600;
                text-decoration: none;
            }
        }
        &--footer {
            margin-top: 1em;
            font-size: 0.5em;
            .lg-vallaID {
                width: 3em;
            }
        }
        &--identity {
            flex: 1;
            display: none;
            min-height: 100%;
            padding-top: 40vh;
            background: $color1;
            .lg-aniversary {
                width: 20%;
                display: inline-block;
                margin: 20vh auto 0;
            }
        }
        @media screen and (min-width: $desktop-min-breakpoint) {
            width: 100%;
            align-items: center;
            &--links {
                flex: 1;
                flex-basis: 60%;
            }
            &--identity {
                display: block;
                flex: 1;
                flex-basis: 40%;
            }
        }
        &.active {
            transform: translateX(0%);
        }
    }
    @media screen and (min-width: $desktop-min-breakpoint) {
        &--menu {

        }
        &--brand {
            padding: 5vh 17% 0;
        }
        &--quick-links {
            img {
                max-width: 33%;
            }
        }
    }
}